<template>
  <div class="home">

      <template v-if="!isRequestError && !isLoading">

        <!-- Ricerca banche dati e materie -->
        <div class="row">
          <div class="col-md-12">
            <div class="card p-0">
              <div class="card-body p-1">
                <h5 class="header-title m-0"><i class="mdi mdi-magnify font-size-22 mr-1 align-middle"></i> <span class="text-uppercase">{{$t('home.ricerca')}}</span></h5>
              </div>
            </div>
          </div>

          <div class="col-12">
            <div class="card">
              <div class="card-body text-center">
                <TabellaRicercaBacheca v-on:is-components-ready="passIsComponentReady" v-on:table-search-has-results="handleTableHasResults($event)" />
              </div>
            </div>
          </div>
        </div>
        <!-- /Ricerca banche dati e materie -->

        <!-- Ultimi concorsi pubblicati -->
        <div v-if="showPrimoPiano" class="row mt-2">
          <div class="col-md-12">
            <div class="card p-0 mb-2">
              <div class="card-body p-1">
                <h5 class="header-title m-0"><i class="mdi mdi-school-outline font-size-22 mr-1 align-middle"></i> <span class="text-uppercase">{{$t('home.ultimiConcorsiPubblicati')}}</span></h5>
              </div>
            </div>
          </div>

          <div class="col-12 d-flex my-1">
            <button type="button" class="ml-auto btn btn-outline-primary btn-sm btn-rounded waves-effect waves-light btn-custom-outline text-uppercase" @click="onVediTutteClick()">{{$t('home.vediTutte')}}</button>
          </div>

          <div id="carousel-example" class="carousel slide w-100 mt-2" data-ride="carousel">
            <div class="carousel-inner row w-100 mx-auto" role="listbox">
              <template v-for="(competition, index) in Math.ceil(lastCompetitions.length/6)">
                <div :class="(index === 0) ? 'carousel-item active' : 'carousel-item'">
                  <template v-for="(x, i) in 6">
                    <div
                      :set="competitionIndex = (index * 6) + i"
                      class="col-12 col-md-4 d-inline-block mb-1"
                    >
                      <div
                        class="card p-1 mb-0" style="min-height: 137px"
                        v-if="(competitionIndex) < lastCompetitions.length"
                      >
                        <div class="card-body p-1">
                          <div class="row">
                            <div class="col-12">
                              <h6><strong>{{lastCompetitions[(competitionIndex)].anno}}</strong></h6>
                              <h6 :id="'competitive-title' + (competitionIndex)" class="mb-0 text-truncate" :data-toggle="verifyIfTextIsTruncated('#competitive-title', (competitionIndex))" data-placement="top" :title="lastCompetitions[(competitionIndex)].ente.ente">{{lastCompetitions[(competitionIndex)].ente.ente}}</h6>
                              <p :id="'competitive-text' + (competitionIndex)" class="text-muted text-truncate-2" :data-toggle="verifyIfTextIsTruncated('#competitive-text', (competitionIndex))" data-placement="bottom" :title="lastCompetitions[(competitionIndex)].concorso" style="font-size:.80em; min-height: 34px">{{lastCompetitions[(competitionIndex)].concorso}}</p>
                              <div class="mt-1">
                                <button type="button" v-if="lastCompetitions[(competitionIndex)].aggiuntoDallUtente !== undefined && lastCompetitions[(competitionIndex)].aggiuntoDallUtente" @click="setSelectedCompetitionDetail(lastCompetitions[((index * 6) + i)])" class="btn btn-primary btn-sm btn-rounded">{{$t('generale.apri')}}</button>
                                <button type="button" v-else @click="addCompetition(lastCompetitions[((index * 6) + i)].id_concorso)" class="btn btn-primary btn-sm btn-rounded">{{$t('home.aggiungiConcorso')}}</button>
                              </div>
                            </div>
                            <!-- Immagine concorso - rimossa
                            <div class="col-5">
                              <div>
                                <img src="../assets/images/widget-img.png" alt="concorso" class="img-fluid">
                              </div>
                            </div>
                            -->
                          </div>
                        </div>
                      </div>
                    </div>
                  </template>
                </div>
              </template>
            </div>
            <ol class="carousel-indicators mb-0 mt-2 position-relative mx-auto">
              <template v-for="(competition, index) in Math.ceil(lastCompetitions.length/6)">
                <li data-target="#carousel-example" class="rounded-indicators" :data-slide-to="index" :class="(index === 0) ? 'active' : ''"></li>
              </template>
            </ol>
          </div>

        </div>
        <!-- /Ultimi concorsi pubblicati -->

        <!-- I tuoi concorsi | DEPRECATED -->
        <div v-if="false" class="row mt-2">
          <div class="col-md-6">
            <div class="card">
              <div class="card-body text-center">
                <h1><i class="mdi mdi-school-outline" style="font-size: 3rem"></i></h1>
                <template v-if="userCompetitions.length > 0">
                  <h5>{{$t('home.ilTuoUltimoConcorso')}}</h5>
                  <ul class="list-group list-group-flush text-left">
                    <li class="list-group-item bg-soft-light">
                      <div class="media">
                        <div class="media-body">
                          <p class="text-muted mb-2">{{userCompetitions[0].concorso}}</p>
                          <h5 class="mb-0">{{userCompetitions[0].anno}}</h5>
                        </div>
                        <div class="icons-lg ml-2 align-self-center">
                          <button type="button" @click="setSelectedCompetitionDetail(userCompetitions[0])" class="btn btn-rounded btn-sm btn-primary">{{$t('home.esercitati')}}</button>
                        </div>
                      </div>
                    </li>

                  </ul>
                </template>
                <template v-else>
                  <h5>{{$t('home.nonHaiAggiuntoNessunaBancaDati')}}</h5>
                </template>

                <router-link :to="{ name: routesNames.ADD_COMPETITION }" class="mt-5 btn btn-rounded btn-sm btn-success">{{$t('home.aggiungiNuovoConcorso')}}</router-link>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="card">
              <div class="card-body text-center">
                <h1><i class="mdi mdi-timeline-text-outline" style="font-size: 3rem"></i></h1>
                  <template v-if="userPaths.length > 0">
                      <h5>{{$t('home.ilTuoUltimoPercorso')}}</h5>
                      <ul class="list-group list-group-flush text-left">
                          <li class="list-group-item bg-soft-light">
                              <div class="media" style="align-items: center">
                                  <div class="media-body">
                                      <p class="text-muted mb-0">{{userPaths[0].argomento}}</p>
                                  </div>
                                  <div class="icons-lg ml-2 align-self-center">
                                      <button type="button" @click="setSelectedPathDetail(userPaths[0])" class="btn btn-rounded btn-sm btn-primary">{{$t('home.esercitati')}}</button>
                                  </div>
                              </div>
                          </li>

                      </ul>
                  </template>
                  <template v-else>
                      <h5>{{$t('home.nonHaiAggiuntoNessunPercorso')}}</h5>
                  </template>
                  <router-link :to="{ name: routesNames.ADD_PATH }" class="mt-5 btn btn-rounded btn-sm btn-success">{{$t('home.aggiungiNuovoPercorso')}}</router-link>
              </div>
            </div>
          </div>
        </div>
        <!-- /I tuoi concorsi -->

      </template>
      <template v-else-if="isRequestError">
          <div class="text-center">
            <img src="../assets/images/errore.png">
          </div>
      </template>

    <!-- Modal message premium -->
    <div id="modalMessagePremium" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modalMessagePremiumLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title mt-0" id="modalMessagePremiumLabel">{{$t('addPath.modale.messagePremium.titolo')}}</h5>
          </div>
          <div id="modal-body-message-premium" class="modal-body">
            {{ $t('addPath.modale.messagePremium.perUtilizzareMateriaPremium') }}
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary waves-effect waves-light" data-dismiss="modal">{{$t('generale.annulla')}}</button>
            <button type="button" class="btn btn-primary waves-effect waves-light" data-dismiss="modal" @click="$router.push({ name: routesNames.SUBSCRIPTION })">{{$t('addPath.modale.messagePremium.passaPremium')}}</button>
          </div>
        </div><!-- /.modal-content -->
      </div><!-- /.modal-dialog -->
    </div>

      <!-- Modal generic error -->
      <div id="modalErrorHome" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modalErrorHomeLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered">
              <div class="modal-content">
                  <div class="modal-header">
                      <h5 class="modal-title mt-0" id="modalErrorHomeLabel">{{$t('home.modale.errore.titolo')}}</h5>
                  </div>
                  <div id="modal-body-home" class="modal-body">
                      <p id="message-body-home"></p>
                  </div>
                  <div class="modal-footer">
                      <button type="button" class="btn btn-primary waves-effect waves-light" data-dismiss="modal">{{$t('generale.ok')}}</button>
                  </div>
              </div><!-- /.modal-content -->
          </div><!-- /.modal-dialog -->
      </div>
      <!-- /Modal generic error -->

      <!-- Modal requests error -->
      <div id="modalRequestsErrorHome" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modalRequestsErrorHomeLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered">
              <div class="modal-content">
                  <div class="modal-header">
                      <h5 class="modal-title mt-0" id="modalRequestsErrorHomeLabel">{{$t('home.modale.errore.titolo')}}</h5>
                  </div>
                  <div id="modal-body-requests-home" class="modal-body">
                      <p id="message-body-requests-home"></p>
                  </div>
                  <div class="modal-footer">
                      <button type="button" id="button-ok-home" class="btn btn-secondary waves-effect waves-light" data-dismiss="modal" @click="isRequestError = true">{{$t('generale.annulla')}}</button>
                      <button type="button" id="button-retry-home" class="btn btn-primary waves-effect waves-light" @click="sendAllRequests()" data-dismiss="modal">{{$t('generale.riprova')}}</button>
                  </div>
              </div><!-- /.modal-content -->
          </div><!-- /.modal-dialog -->
      </div>
      <!-- /Modal requests error -->


    <!-- Modal generic error -->
    <div id="modalGenericErrorAddPath" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modalGenericErrorAddPathLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title mt-0" id="modalGenericErrorAddPathLabel">{{$t('addPath.modale.errore.titolo')}}</h5>
          </div>
          <div id="modal-body-generic-add-path-error" class="modal-body">
            <p id="message-body-generic-add-path-error"></p>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary waves-effect waves-light" data-dismiss="modal">{{$t('generale.ok')}}</button>
          </div>
        </div><!-- /.modal-content -->
      </div><!-- /.modal-dialog -->
    </div>
    <!-- /Modal add path error -->


    <!-- Modal competitions request error -->
    <div id="modalPathsRequestsError" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modalPathsRequestsErrorLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title mt-0" id="modalPathsRequestsErrorLabel">Concorsando.it</h5>
          </div>
          <div id="modal-body-paths-requests-error" class="modal-body">
            <p id="message-body-paths-requests-error"></p>
          </div>
          <div class="modal-footer">
            <button type="button" id="button-ok-paths" class="btn btn-secondary waves-effect waves-light" data-dismiss="modal" @click="$router.push({ name: routesNames.HOME })">{{$t('generale.annulla')}}</button>
            <button type="button" id="button-retry-paths" class="btn btn-primary waves-effect waves-light" @click="getUtentePercorsi()" data-dismiss="modal">{{$t('generale.riprova')}}</button>
          </div>
        </div><!-- /.modal-content -->
      </div><!-- /.modal-dialog -->
    </div>
    <!-- /Modal competitions request error -->



    <!-- Modal confirm add materia -->
    <div id="modalConfirmConcorso" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modalConfirmMateria" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title mt-0" id="modalConcorsoLabel">Concorsando.it</h5>
          </div>
          <div id="modal-body-confirm-materia" class="modal-body">
            {{ $t('home.modaleConfirmConcorso.message') }}
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary waves-effect waves-light" data-dismiss="modal">{{$t('generale.annulla')}}</button>
            <button type="button" id="button-confirm-materia" class="btn btn-primary waves-effect waves-light"  data-dismiss="modal" @click="addCompetition(idConcorsoByLink)">{{$t('generale.conferma')}}</button>
          </div>
        </div><!-- /.modal-content -->
      </div><!-- /.modal-dialog -->
    </div>
    <!-- /Modal confirm add concorso -->

    <!-- Modal confirm add materia -->
    <div id="modalConfirmMateria" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modalConfirmMateria" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title mt-0" id="modalMateriaLabel">Concorsando.it</h5>
          </div>
            <div id="modal-body-confirm-materia" class="modal-body">
              {{ $t('home.modaleConfirmMateria.message') }}
            </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary waves-effect waves-light" data-dismiss="modal">{{$t('generale.annulla')}}</button>
            <button type="button" id="button-confirm-materia" class="btn btn-primary waves-effect waves-light"  data-dismiss="modal" @click="addPath(idMateriaByLink)">{{$t('generale.conferma')}}</button>
          </div>
        </div><!-- /.modal-content -->
      </div><!-- /.modal-dialog -->
    </div>
    <!-- /Modal confirm add concorso -->
  </div>
</template>

<script>
  import 'bootstrap'
  import $ from 'jquery'
  import { mapActions, mapGetters } from 'vuex'
  import { MODULES } from "@/store/modules-enum";
  import { AUTH_GETTERS } from "@/store/auth/getters-enum";
  import { COMPETITION_ACTIONS } from "@/store/competition/actions-enum";
  import { COMPETITION_GETTERS } from "@/store/competition/getters-enum";
  import _ from 'lodash'
  import {UTILITY_MUTATIONS} from "@/store/utility/mutations-enum";
  import {UTILITY_ACTIONS} from "@/store/utility/actions-enum";
  import {PATH_ACTIONS} from "@/store/path/actions-enum";
  import {PATH_GETTERS} from "@/store/path/getters-enum";
  import {getDistanceWith, isTimeChecked, setTimeChecked} from "@/lib/utils";
  import {systemTimeDelay} from "@/config";
  import {UTILITY_GETTERS} from "@/store/utility/getters-enum";
  import {PROFILE_ACTIONS} from "@/store/profile/actions-enum";
  import {routesEnums} from "@/helpers/enums";
  import {isRequestErrorRetryable, retryPromise} from "../lib/utils";
  import TabellaRicercaBacheca from "@/components/TabellaRicercaBacheca"
  import {Storage} from "../helpers/enums";

export default {
    name: 'Home',
    components: {
        TabellaRicercaBacheca
    },
    data() {
        return {
            isRequestError: false,
            isLoading: true,
            routesNames: routesEnums,
            requests: [],
            showPrimoPiano: true,
            idConcorsoByLink: null,
            idMateriaByLink: null
        }
    },
    computed: {
        ...mapGetters(MODULES.AUTH, [AUTH_GETTERS.AUTHENTICATIONS, AUTH_GETTERS.IS_AUTHENTICATED]),
        ...mapGetters(MODULES.COMPETITION, [COMPETITION_GETTERS.COMPETITIONS, COMPETITION_GETTERS.USER_COMPETITIONS, COMPETITION_GETTERS.ENTITIES]),
        ...mapGetters(MODULES.PATH, [PATH_GETTERS.PATHS, PATH_GETTERS.USER_PATHS, PATH_GETTERS.USER_PATHS]),
        ...mapGetters(MODULES.UTILITY, [UTILITY_GETTERS.GET_LOCAL_UPTIME]),
        lastCompetitions() {
            const competitionsOfUser = _.cloneDeep(this.userCompetitions)
            let competitionsFiltered = []
            _.cloneDeep(this.competitions).forEach(competition => {
                if (competition.stato === 1 || competition.stato === 2) {
                    competitionsOfUser.forEach(userCompetition => {
                        if (userCompetition.id_concorso === competition.id_concorso) {
                            competition = _.cloneDeep(userCompetition)
                            competition.aggiuntoDallUtente = true
                        }
                    })
                    competitionsFiltered.push(competition)
                }
            })
            return _.orderBy(competitionsFiltered,  [(obj) => new Date(obj.data_inserimento)], ['desc'])
        }
    },
    mounted() {
          this.backToTop()
          this.setRequests()
          this.verifyUptime()
    },
    methods: {
        ...mapActions(MODULES.COMPETITION, [COMPETITION_ACTIONS.GET_ENTITIES, COMPETITION_ACTIONS.GET_COMPETITIONS, COMPETITION_ACTIONS.GET_COMPETITION, COMPETITION_ACTIONS.GET_USER_COMPETITIONS, COMPETITION_ACTIONS.SET_SELECTED_COMPETITION]),
        ...mapActions(MODULES.UTILITY, [UTILITY_ACTIONS.GET_UPTIME]),
        ...mapActions(MODULES.PATH, [PATH_ACTIONS.GET_PATHS, PATH_ACTIONS.GET_PATH, PATH_ACTIONS.SET_SELECTED_PATH, PATH_ACTIONS.GET_USER_PATHS]),
        ...mapActions(MODULES.PROFILE, [PROFILE_ACTIONS.GET_USER]),
        initCarousel () {
            $('#carousel-example').carousel({
                interval: 8000
            })
        },
        verifyUptime () {
            this.$emit('is-components-ready', 'home', false)
            const context = this
            this.getUptime()
                .then((res) => {
                    context.verifySystemTime(res.headers.date)
                    if (context.competitions.length < 1 || context.entities.length < 1 || context.paths.length < 1 ) {
                        context.sendAllRequests(false, res.body)
                    } else {
                        if (res.body > context.getLocalUptime) {
                            context.sendAllRequests(false, res.body)
                        } else {
                            context.sendAllRequests(true)
                        }
                    }
                })
                .catch(() => {
                    context.sendAllRequests(false)
                })
                .finally(() => {
                    context.getUtenteConcorsi(context)
                    context.getUtentePercorsi(context)
                    context.initCarousel()
                })
        },
        verifyDeepLinkConcorso(){
          const idConcorso = sessionStorage.getItem(Storage.DOWNLOAD_DATA_ID_CONCORSO)
          if(idConcorso){
            sessionStorage.removeItem(Storage.DOWNLOAD_DATA_ID_CONCORSO)
            return idConcorso
          }
        },
        verifyDeepLinkArgomento(){
          const idArgomento = sessionStorage.getItem(Storage.DOWNLOAD_DATA_ID_ARGOMENTO)
          if(idArgomento){
            sessionStorage.removeItem(Storage.DOWNLOAD_DATA_ID_ARGOMENTO)
            return idArgomento
          }
        },
        verifySystemTime (headersDate) {
            if (!isTimeChecked()) {
                setTimeChecked(true);
                const timeDiff = getDistanceWith(headersDate)
                if (timeDiff > systemTimeDelay) {
                    $('#modalTime').modal('show')
                }
            }
        },
        sendAllRequests (onlyUser, uptime) {
            $('.modal').modal('hide')
            this.isRequestsReady('sendAllRequests', false)
            const context = this
            context.isLoading = true
            $('#button-retry-home').attr('disabled', 'disabled')
            const setupPromise = context.getUser(true).then(res => {
                if (res.userCleared || !onlyUser) {
                    return Promise.all([
                        context.getCompetitions(context),
                        context.getEntities(context),
                        context.getPaths(context)
                    ])
                }
            })
            setupPromise
                .then(() => {
                    if (uptime !== undefined && uptime !== null) {
                        this.$store.commit(MODULES.UTILITY + "/" + UTILITY_MUTATIONS.SET_UPTIME, uptime)
                    }
                    $('#modalRequestsErrorHome').modal('hide')
                    context.isLoading = false
                    context.isRequestsReady('sendAllRequests', true)
                    context.isRequestError = false
                })
                .catch(() => {
                    context.isLoading = false
                    context.isRequestError = true
                    context.$emit('is-components-ready', 'home', true)
                    $('#modalRequestsErrorHome').data('bs.modal', null)
                    $('#modalRequestsErrorHome').modal({backdrop: 'static', keyboard: false})
                    $('#button-retry-home').removeAttr('disabled')
                    $('#message-body-requests-home').html(context.$t('home.modale.errore.richieste'))
                })
        },
        verifyIfTextIsTruncated (elementID, index) {
            const context = this
            $( document ).ready(function() {
                const stringElement = elementID + index
                const $element = $(stringElement);
                const $c = $element
                  .clone()
                  .removeClass('text-truncate')
                  .removeClass('text-truncate-2')
                  .css({display: 'inline', width: 'auto', visibility: 'hidden'})
                  .appendTo('body');
                if ($c.width() > ((elementID === '#competitive-title') ? 250 : 400)) {
                    $element.attr('data-toggle', 'tooltip');
                } else {
                    $element.removeAttr('data-placement');
                    $element.removeAttr('title');
                }

                $c.remove();
                if (index === (context.lastCompetitions.length - 2)) {
                    $('[data-toggle="tooltip"]').tooltip()
                }
            });
        },
        addCompetition (id_concorso) {
            this.$emit('is-components-ready', 'home', false)
            const context = this
            this.getCompetition({
                idConcorso: id_concorso,
                opts: null
            })
                .then(() => {
                    context.getUtenteConcorsi(context, id_concorso)
                })
                .catch(() => {
                    context.$emit('is-components-ready', 'home', true)
                    $('#modalConfirmConcorsoMateria').modal('show')
                    $('#message-body-home').html(context.$t('home.modale.errore.aggiungiConcorso'))
                })
        },
        addPath (id_argomento) {
            this.$emit('is-components-ready', 'home', false)
            const context = this
            this.getPath({
              idPercorso: id_argomento,
              opts: null
            }).then((path) => {
                  if (path.premium && this.profile.livello >= 2 && this.profile.livello < 502) {
                    $('#modalMessagePremium').modal('show')
                    context.$emit('is-components-ready', 'home', true)
                  } else {
                    context.getUtentePercorsiCheck(path)
                  }
                })
                .catch(() => {
                  context.$emit('is-components-ready', 'home', true)
                  $('#modalGenericErrorAddPath').modal('show')
                  $('#message-body-generic-add-path-error').html(context.$t('addPath.modale.errore.aggiungiPercorso'))
                })

        },
        getUtentePercorsiCheck (path) {
            this.isRequestsReady('getUserPaths', false)
            const context = this
            this.getUserPaths()
                .then(() => {
                  if (path !== undefined) {
                    context.goToPath(path)
                  } else {
                    context.$emit('is-components-ready', 'home', true)
                  }
                })
                .catch(() => {
                  context.$emit('is-components-ready', 'home', true)
                  $('#button-retry-add-path').removeAttr('disabled')
                  $('#modalPathsRequestsError').data('bs.modal', null)
                  $('#modalPathsRequestsError').modal({backdrop: 'static', keyboard: false})
                  $('#message-body-paths-requests-error').html(context.$t('addPath.modale.errore.percorsiUtente'))
                })
          },
          goToPath (path) {
            const context = this
            this.setSelectedPath(path.id_argomento)
                .then(() => {
                  context.$emit('is-components-ready', 'home', true)
                  this.$router.push({name: routesEnums.PATH_DETAIL_WRAPPER})
                })
                .catch(() => {
                  context.$emit('is-components-ready', 'home', true)
                  $('#modalGenericErrorAddPath').modal('show')
                  $('#message-body-generic-add-path-error').html(context.$t('addPath.modale.errore.percorsiUtente'))
                })
          },
        getUtenteConcorsi (context, idConcorso) {
            this.$emit('is-components-ready', 'home', false)
            this.isRequestsReady('getUserCompetitions', false)
            retryPromise(() => context.getUserCompetitions(), 1, 3000, isRequestErrorRetryable)
                .then(() => {
                    if (idConcorso) {
                         context.setSelectedCompetitionDetail({ id_concorso: idConcorso })
                    }
                    context.isRequestsReady('getUserCompetitions', true)
                })
                .catch((e) => {
                    context.$emit('is-components-ready', 'home', true)
                    console.log("Got error with getUserCompetitions");
                    console.error(e);
                    // context.isRequestError = true
                    // $('#modalErrorHome').modal({backdrop: 'static', keyboard: false})
                    // $('#message-body-home').html(context.$t('home.modale.errore.concorsiUtente'))
                })
        },
        setSelectedCompetitionDetail (row) {
            let context = this
            this.setSelectedCompetition(row.id_concorso)
                .then(() => {
                    this.$emit('is-components-ready', 'home', true)
                    this.$router.push({ name: routesEnums.COMPETITION_DETAIL_WRAPPER })
                })
                .catch(() => {
                    $('#modalErrorHome').modal('show')
                    $('#message-body-home').html(context.$t('home.modale.errore.concorsoNonTrovato'))
                })
        },
        backToTop () {
            document.body.scrollTop = 0
            document.documentElement.scrollTop = 0
        },
        setSelectedPathDetail (row) {
            let context = this
            this.setSelectedPath(row.id_argomento)
                .then(() => {
                    this.$router.push({ name: routesEnums.PATH_DETAIL_WRAPPER })
                })
                .catch(() => {
                    $('#modalErrorHome').modal('show')
                    $('#message-body-home').html(context.$t('home.modale.errore.percorsoNonTrovato'))
                })
        },
        getUtentePercorsi (context) {
            this.isRequestsReady('getUserPaths', false)
            retryPromise(() => context.getUserPaths(), 1, 3000, isRequestErrorRetryable)
                .then(() => {
                    context.isRequestsReady('getUserPaths', true)
                })
                .catch((e) => {
                    context.$emit('is-components-ready', 'home', true)
                    console.log("Got error with getUserPaths");
                    console.error(e);
                    // $('#modalErrorHome').modal({backdrop: 'static', keyboard: false})
                    // $('#message-body-home').html(context.$t('home.modale.errore.percorsiUtente'))
                })
        },
        setRequests () {
            this.requests = [
                {
                    label: 'sendAllRequests',
                    isComplete: true
                },
                {
                    label: 'getUserCompetitions',
                    isComplete: true
                },
                {
                    label: 'getUserPaths',
                    isComplete: true
                }
            ]
        },
        isRequestsReady (label, state) {
            const request = _.find(this.requests, (r) => r.label === label)
            if (request !== undefined) {
                request.isComplete = state
            }
            const isRequestPending = _.find(this.requests, (r) => r.isComplete === false)
            if (isRequestPending === undefined) {
                this.$emit('is-components-ready', 'home', true)
                this.manageAutomaticDownloadConcorsiOrMaterie();
            }
        },
        manageAutomaticDownloadConcorsiOrMaterie(){
          this.idConcorsoByLink = this.verifyDeepLinkConcorso()
          this.idMateriaByLink = this.verifyDeepLinkArgomento()
          if (this.idConcorsoByLink) {
            const addedConcorso = this.lastCompetitions?.length > 0 ? this.lastCompetitions?.find(v => v.id_concorso === this.idConcorsoByLink) : undefined;
            if(addedConcorso && addedConcorso.aggiuntoDallUtente){
              this.setSelectedCompetitionDetail({ id_concorso: this.idConcorsoByLink })
            } else {
              $('#modalConfirmConcorso').modal('show')
            }
          } else if (this.idMateriaByLink) {
            const isAddedMateria =  this.verifyIfPathIsAlreadyAddedByUser(this.idMateriaByLink)
            if(isAddedMateria){
              this.addPath(this.idMateriaByLink)
            } else {
              $('#modalConfirmMateria').modal('show')
            }
          }
        },
        verifyIfPathIsAlreadyAddedByUser (id_argomento) {
          if (id_argomento !== null && id_argomento !== undefined && id_argomento !== '') {
            return this.filterPathsAddedByUser(id_argomento)
          }
          return false
        },
        filterPathsAddedByUser(filter) {
          const pathAddedByUser = _.filter(this.userPaths, function (userPath) {
            return userPath.id_argomento === filter
          })
          return pathAddedByUser.length > 0;
        },
        passIsComponentReady(label, state) {
            this.$emit('is-components-ready', label, state)
        },
        handleTableHasResults(val) {
            this.showPrimoPiano = !val;
        },
        onVediTutteClick() {
            this.$router.push({name: routesEnums.ADD_COMPETITION, hash: '#v-pills-left-messages'});
        },
    }
  }
</script>

<style scoped lang="scss">
    html.theme-light, html.theme-dark {
        .rounded-indicators {
            height: 15px;
            width: 15px;
            border-radius: 50%;
        }

        .text-truncate-2 {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }
    }
</style>
