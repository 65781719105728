<template>
    <div id="tabella-ricerca-bacheca">
        <div class="row align-items-center my-1">
            <div class="col-12 d-flex">
                <button type="button" class="ml-auto btn btn-outline-primary btn-sm btn-rounded waves-effect waves-light btn-custom-outline text-uppercase" @click="onAdvancedClick()">{{$t('tabellaRicercaBacheca.avanzata')}}</button>
            </div>
            <div class="col-12 d-flex flex-row flex-nowrap">
                <img style="max-width: 10%; min-width: 80px" src="../assets/images/search25.png" class="pr-2 vertical-align-text-bottom"/>
                <div class="input-group flex-grow-1 my-auto">
                    <input type="text" id="input-search" :class="[((searchKeyWord !== '') ? 'border-right-0' : ''), 'form-control']" @focusin="changeBorderColorDelete('#b1bbc4')" @focusout="changeBorderColorDelete('#ced4da')" v-model="searchKeyWord" :placeholder="$t('tabellaRicercaBacheca.cerca')" aria-describedby="basic-addon2">
                    <div class="input-group-append" v-if="searchKeyWord !== ''">
                        <span class="icon-container input-group-text bg-transparent border-left-0 cursorPointer" @click="searchKeyWord = ''" style="border-color: #b1bbc4;" id="delete-search-icon"><i class="mdi mdi-close"></i></span>
                    </div>
                </div>
            </div>
        </div>
        <template v-if="searchTableElementsFiltered.length > 0">
            <div class="table-responsive">
                <b-table
                    :tbody-tr-class="isPremium"
                    head-variant="light"
                    :per-page="perPage"
                    :current-page="currentPage"
                    :fields="fields"
                    :items="searchTableElementsFiltered"
                >
                    <!-- Head table -->
                    <template v-slot:head(titolo)="data">
                        <div>{{$t('tabellaRicercaBacheca.tabella.titolo')}}</div>
                    </template>
                    <template v-slot:head(azioni)="data">
                        <div class="text-center"></div>
                    </template>
                    <!-- /Head table -->

                    <template v-slot:cell(titolo)="data">
                        <div class="text-left">
                            <div class="row">
                                <div class="col-12">
                                    <h6><strong>{{data.item.title}}</strong></h6>
                                    <h6 class="mb-0">
                                        <i v-if="data.item.type === 'BANCA_DATI'" class="mdi mdi-bank font-icon"/>
                                        {{data.item.subtitle}}
                                    </h6>
                                    <p class="text-muted mb-1" style="font-size:.80em;">
                                        <i v-if="data.item.type === 'BANCA_DATI'" class="mdi mdi-calendar-range font-icon"></i>
                                        {{data.item.description}}
                                    </p>
                                    <div class="d-flex justify-content-end">
                                        <span v-if="data.item.premium && isDemoUser" class="badge badge-danger">Premium</span>
                                        <span v-if="data.item.type === 'BANCA_DATI'" class="badge badge-primary">Banca Dati</span>
                                        <span v-if="data.item.type === 'MATERIA'" class="badge badge-primary">Materia</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>

                    <template v-slot:cell(azioni)="data">
                        <div class="text-center">
                            <button type="button" v-if="giaAggiunta(data.item, data.index)" @click="apriElemento(data.item)" class="btn btn-primary btn-sm btn-rounded">{{$t('generale.apri')}}</button>
                            <button type="button" v-else @click="aggiungiElemento(data.item)" class="btn btn-primary btn-sm btn-rounded">{{$t('tabellaRicercaBacheca.tabella.aggiungi')}}</button>
                        </div>
                    </template>
                </b-table>
            </div>

            <div class="mt-4">
                <div class="pagination pagination-rounded justify-content-center mb-0">
                    <b-pagination v-model="currentPage" :per-page="perPage" pills :total-rows="searchTableElementsFiltered.length"></b-pagination>
                </div>
            </div>
        </template>
        <template v-else-if="searchKeyWord != null && searchKeyWord !== ''">
            <div class="text-center">{{$t('tabellaRicercaBacheca.tabella.nonHaiTrovatoRisultati')}}</div>
            <div class="text-center">
                <button type="button" class="btn btn-primary btn-sm btn-rounded" @click="onAdvancedClick()">
                    {{$t('tabellaRicercaBacheca.tabella.vaiAllaRicercaAvanzata')}}
                </button>
            </div>
        </template>

        <!-- Modal avanzata -->
        <div id="modalAvanzata" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modalAvanzataLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title mt-0" id="modalAvanzataLabel">{{$t('tabellaRicercaBacheca.modalAvanzata.title')}}</h5>
                    </div>
                    <div id="modal-avanzata-message" class="modal-body">
                        {{ $t('tabellaRicercaBacheca.modalAvanzata.body') }}
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-primary waves-effect waves-light" data-dismiss="modal" @click="$router.push({ name: routesNames.ADD_COMPETITION })">{{$t('tabellaRicercaBacheca.modalAvanzata.buttonBancheDati')}}</button>
                        <button type="button" class="btn btn-primary waves-effect waves-light" data-dismiss="modal" @click="$router.push({ name: routesNames.ADD_PATH })">{{$t('tabellaRicercaBacheca.modalAvanzata.buttonMaterie')}}</button>
                    </div>
                </div><!-- /.modal-content -->
            </div><!-- /.modal-dialog -->
        </div>
        <!-- /Modal avanzata -->

        <!-- Modal message premium -->
        <div id="modalMessagePremium" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modalMessagePremiumLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title mt-0" id="modalMessagePremiumLabel">{{$t('addPath.modale.messagePremium.titolo')}}</h5>
                    </div>
                    <div id="modal-body-message-premium" class="modal-body">
                        {{ $t('addPath.modale.messagePremium.perUtilizzareMateriaPremium') }}
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary waves-effect waves-light" data-dismiss="modal">{{$t('generale.annulla')}}</button>
                        <button type="button" class="btn btn-primary waves-effect waves-light" data-dismiss="modal" @click="$router.push({ name: routesNames.SUBSCRIPTION })">{{$t('addPath.modale.messagePremium.passaPremium')}}</button>
                    </div>
                </div><!-- /.modal-content -->
            </div><!-- /.modal-dialog -->
        </div>
        <!-- /Modal message premium -->

        <!-- Modal add path error -->
        <div id="modalGenericErrorAddPath" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modalGenericErrorAddPathLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title mt-0" id="modalGenericErrorAddPathLabel">{{$t('addPath.modale.errore.titolo')}}</h5>
                    </div>
                    <div id="modal-body-generic-add-path-error" class="modal-body">
                        <p id="message-body-generic-add-path-error"></p>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-primary waves-effect waves-light" data-dismiss="modal">{{$t('generale.ok')}}</button>
                    </div>
                </div><!-- /.modal-content -->
            </div><!-- /.modal-dialog -->
        </div>
        <!-- /Modal add path error -->


        <!-- Modal paths request error -->
        <div id="modalPathsRequestsError" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modalPathsRequestsErrorLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title mt-0" id="modalPathsRequestsErrorLabel">{{$t('competitions.modale.errore.titolo')}}</h5>
                    </div>
                    <div id="modal-body-paths-requests-error" class="modal-body">
                        <p id="message-body-paths-requests-error"></p>
                    </div>
                    <div class="modal-footer">
                        <button type="button" id="button-ok-paths" class="btn btn-secondary waves-effect waves-light" data-dismiss="modal" @click="$router.push({ name: routesNames.HOME })">{{$t('generale.annulla')}}</button>
                        <button type="button" id="button-retry-paths" class="btn btn-primary waves-effect waves-light" @click="getUtentePercorsi()" data-dismiss="modal">{{$t('generale.riprova')}}</button>
                    </div>
                </div><!-- /.modal-content -->
            </div><!-- /.modal-dialog -->
        </div>
        <!-- /Modal paths request error -->

        <!-- Modal add competition error -->
        <div id="modalGenericErrorAddCompetition" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="mmodalGenericErrorAddCompetitionLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title mt-0" id="modalGenericErrorAddCompetitionLabel">{{$t('addCompetition.modale.errore.titolo')}}</h5>
                    </div>
                    <div id="modal-body-generic-add-error" class="modal-body">
                        <p id="message-body-generic-add-error"></p>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-primary waves-effect waves-light" data-dismiss="modal">{{$t('generale.ok')}}</button>
                    </div>
                </div><!-- /.modal-content -->
            </div><!-- /.modal-dialog -->
        </div>
        <!-- /Modal add competition error -->


        <!-- Modal competitions request error -->
        <div id="modalCompetitionsRequestsError" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modalCompetitionsRequestsErrorLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title mt-0" id="modalCompetitionsRequestsErrorLabel">{{$t('competitions.modale.errore.titolo')}}</h5>
                    </div>
                    <div id="modal-body-competitions-requests-error" class="modal-body">
                        <p id="message-body-competitions-requests-error"></p>
                    </div>
                    <div class="modal-footer">
                        <button type="button" id="button-ok-competitions" class="btn btn-secondary waves-effect waves-light" data-dismiss="modal" @click="$router.push({ name: 'Home' })">{{$t('generale.annulla')}}</button>
                        <button type="button" id="button-retry-competitions" class="btn btn-primary waves-effect waves-light" @click="getUtenteConcorsi()" data-dismiss="modal">{{$t('generale.riprova')}}</button>
                    </div>
                </div><!-- /.modal-content -->
            </div><!-- /.modal-dialog -->
        </div>
        <!-- /Modal competitions request error -->
    </div>
</template>

<script>

import 'bootstrap'
import $ from 'jquery'
import {mapActions, mapGetters} from 'vuex'
import {MODULES} from "@/store/modules-enum";
import {AUTH_GETTERS} from "@/store/auth/getters-enum";
import {COMPETITION_GETTERS} from "@/store/competition/getters-enum";
import {COMPETITION_ACTIONS} from "@/store/competition/actions-enum";
import _ from 'lodash'
import {PATH_GETTERS} from "@/store/path/getters-enum";
import {PATH_ACTIONS} from "@/store/path/actions-enum";
import {UTILITY_GETTERS} from "@/store/utility/getters-enum";
import {routesEnums} from "@/helpers/enums";
import {BPagination, BTable} from 'bootstrap-vue'

const SOTTOPERCORSI_ESCLUSI = [
    'Non ancora classificati'
    , 'Non classificati'
    , 'Non ancora classificate'
    , 'Non ancora classifcate'
    , 'Non ancora classficati'
    , 'Non anccora classificati'
    , 'Non anora classificati'
    , 'Non ancora classificat'
    , 'Ancora non classificati'
    , 'Non anora classifcate'
    , 'Non ancora clsssificati'
    , 'Non acora classificati'
    , 'Non ancora classifciati'
    , 'Non anora classifcati'
]

const removeElemRegex = new RegExp(/[.-]/, "g")
function normalizeSearchString(s) {
    if (!!s && s !== "") {
        return s.replaceAll(removeElemRegex, "").toLowerCase();
    } else {
        return s;
    }
}


export default {
    name: "TabellaRicercaBacheca",
    components: {
        BTable,
        BPagination,
    },
    data () {
        return {
            componentName: 'tabellaRicercaBacheca',
            routesNames: routesEnums,
            searchKeyWord: '',
            perPage: 10,
            currentPage: 1,
            fields: [
                {
                    key: 'Titolo',
                    tdClass: 'col-9',
                    thClass: 'd-none',
                },
                {
                    key: 'azioni',
                    tdClass: 'col-3 verticalCenter',
                    thClass: 'd-none',
                }
            ],
        }
    },
    computed: {
        ...mapGetters(MODULES.AUTH, [AUTH_GETTERS.AUTHENTICATIONS, AUTH_GETTERS.IS_AUTHENTICATED, AUTH_GETTERS.PROFILE]),
        ...mapGetters(MODULES.COMPETITION, [COMPETITION_GETTERS.ENTITIES, COMPETITION_GETTERS.COMPETITIONS, COMPETITION_GETTERS.USER_COMPETITIONS, COMPETITION_GETTERS.GROUPED_COMPETITIONS, COMPETITION_GETTERS.ENTITIES]),
        ...mapGetters(MODULES.PATH, [PATH_GETTERS.PATHS, PATH_GETTERS.USER_PATHS]),
        ...mapGetters(MODULES.UTILITY, [UTILITY_GETTERS.GET_LOCAL_UPTIME]),
        tableElements() {
            return _.orderBy([
                ...this.competitions.map((el) => ({
                    title: el.concorso,
                    subtitle: el.ente.ente,
                    description: el.anno,
                    sortKey: el.anno,
                    sortKey2: el.concorso,
                    searchKey: normalizeSearchString([el.concorso, el.ente.ente, el.anno,  (el.description || '')].join(" ")),
                    type: "BANCA_DATI",
                    id_concorso: el.id_concorso,
                })),
                ...this.paths.map((el) => {
                    const sottoPercorsi = this.getSottoPercorsi(el);
                    return {
                        title: el.argomento,
                        subtitle: sottoPercorsi.length > 0 ? this.$t("tabellaRicercaBacheca.tabella.materiaSubtitle") : "",
                        description: this.printSottoPercorsi(sottoPercorsi),
                        sortKey: "",
                        sortKey2: el.argomento,
                        searchKey: normalizeSearchString([el.argomento, ...sottoPercorsi, (el.description || '')].filter(x => x).join(" ")),
                        type: "MATERIA",
                        premium: el.premium,
                        id_argomento: el.id_argomento,
                    }
                }),
            ], ["sortKey", "sortKey2"], ["desc", "asc"])
        },
        searchTableElementsFiltered() {
            const searchInput = normalizeSearchString((this.searchKeyWord || ""))
            const searchStrings = searchInput.split(" ")
                .filter(x => !!x && x !== "");

            if (searchStrings.length <= 0) {
                return [];
            }
            return _.chain(this.tableElements)
                .filter(x => {
                    const searchField = _.get(x, "searchKey", "")
                    return searchStrings.every(searchString => searchField.indexOf(searchString) > -1);
                })
                .value();
        },
        isDemoUser() {
            return this.profile.livello >= 2;
        },
    },
    watch: {
        'searchTableElementsFiltered.length': {
            handler(newValue) {
                if (newValue > 0) {
                    this.$emit('table-search-has-results', true);
                } else {
                    this.currentPage = 1;
                    this.$emit('table-search-has-results', false);
                }
            },
        }
    },
    mounted() {
    },
    methods: {
        ...mapActions(MODULES.PATH, [PATH_ACTIONS.GET_PATHS, PATH_ACTIONS.GET_USER_PATHS, PATH_ACTIONS.GET_PATH, PATH_ACTIONS.SET_SELECTED_PATH]),
        ...mapActions(MODULES.COMPETITION, [COMPETITION_ACTIONS.GET_ENTITIES, COMPETITION_ACTIONS.GET_COMPETITIONS, COMPETITION_ACTIONS.GET_COMPETITION, COMPETITION_ACTIONS.GET_USER_COMPETITIONS, COMPETITION_ACTIONS.SET_SELECTED_COMPETITION]),
        changeBorderColorDelete (color) {
            $('#delete-search-icon').css('border', '1px solid ' + color)
        },
        giaAggiunta(item, index) {
            if (item.type === "BANCA_DATI") {
                return this.verifyIfCompetitionIsAlreadyAddedByUser(item.id_concorso)
            } else if (item.type === "MATERIA") {
                return this.verifyIfPathIsAlreadyAddedByUser(item.id_argomento)
            }
        },
        apriElemento(item) {

            // TODO: implementa
            if (item.type === "BANCA_DATI") {
                this.goToCompetition(item.id_concorso)
            } else if (item.type === "MATERIA") {
                this.goToPath(item)
            }
        },
        aggiungiElemento(item) {
            if (item.type === "BANCA_DATI") {
                this.addCompetition(item.id_concorso)
            } else if (item.type === "MATERIA") {
                this.addPath(item)
            }
        },
        isPremium (item, type) {
            let classes = ''
            if (type === 'row' && item.premium !== undefined && item.premium && this.profile.livello >= 2) {
                classes += ' bg-premium'
            }
            return classes
        },
        getSottoPercorsi (path) {
            const sottoPercorsi = _.chain(path)
                .get("sotto_percorsi", {})
                .values()
                // .sortBy(["id_sotto_percorso"])
                .map(x => _.get(x, "nome"))
                .sortBy()
                .filter(x => x)
                .value();
            // Se serve fare altri filtri, ad esempio eliminare "Non ancora classificati"
            return sottoPercorsi.filter(x => !SOTTOPERCORSI_ESCLUSI.includes(x));
        },
        /**
         * Stampa sottopercorsi. Ispirato a `AddPath.printString`
         */
        printSottoPercorsi (sottoPercorsi) {
            return sottoPercorsi.length > 0
                ? sottoPercorsi.join(" - ")
                : ""
        },
        //#region ---- Gestione percorsi
        verifyIfPathIsAlreadyAddedByUser (id_argomento) {
            if (id_argomento !== null && id_argomento !== undefined && id_argomento !== '') {
                const pathAddedByUser = _.filter(this.userPaths, function (userPath) {
                    return userPath.id_argomento === id_argomento
                })
                return pathAddedByUser.length > 0;
            }
            return false
        },
        goToPath (item) {
            // Copiata da AddPath.goToPath
            if (item.premium && this.profile.livello >= 2 && this.profile.livello < 502) {
                $('#modalMessagePremium').modal('show')
            } else {
                this.setSelectedPath(item.id_argomento)
                    .then(() => {
                        this.$router.push({ name: routesEnums.PATH_DETAIL_WRAPPER })
                    })
                    .catch(() => {
                        this.$emit('is-components-ready', this.componentName, true)
                        $('#modalGenericErrorAddPath').modal('show')
                        $('#message-body-generic-add-path-error').html(this.$t('addPath.modale.errore.percorsiUtente'))
                    })
            }
        },
        addPath (item) {
            // Copiata da AddPath.addPath
            if (item.premium && this.profile.livello >= 2 && this.profile.livello < 502) {
                $('#modalMessagePremium').modal('show')
            } else {
                this.$emit('is-components-ready', this.componentName, false)
                this.getPath({
                    idPercorso: item.id_argomento,
                    opts: null
                })
                    .then(() => {
                        this.getUtentePercorsi(item)
                    })
                    .catch(() => {
                        this.$emit('is-components-ready', this.componentName, true)
                        $('#modalGenericErrorAddPath').modal('show')
                        $('#message-body-generic-add-path-error').html(this.$t('addPath.modale.errore.aggiungiPercorso'))
                    })
            }
        },
        getUtentePercorsi (item) {
            this.isRequestsReady('getUserPaths', false)
            this.$emit('is-components-ready', this.componentName, false)
            this.getUserPaths()
                .then(() => {
                    this.$emit('is-components-ready', this.componentName, true)
                    if (item !== undefined) {
                        this.goToPath(item)
                    }
                })
                .catch(() => {
                    this.$emit('is-components-ready', this.componentName, true)
                    $('#button-retry-add-path').removeAttr('disabled')
                    $('#modalPathsRequestsError').data('bs.modal', null)
                    $('#modalPathsRequestsError').modal({backdrop: 'static', keyboard: false})
                    $('#message-body-paths-requests-error').html(this.$t('addPath.modale.errore.percorsiUtente'))
                })
        },
        //#endregion
        //#region ---- Gestione concorsi
        verifyIfCompetitionIsAlreadyAddedByUser (id_concorso) {
            if (id_concorso !== null && id_concorso !== undefined && id_concorso !== '') {
                const competitionAddedByUser = _.filter(this.userCompetitions, function (userCompetition) {
                    return userCompetition.id_concorso === id_concorso
                })
                return competitionAddedByUser.length > 0;
            }
            return false
        },
        goToCompetition(idConcorso) {
            this.setSelectedCompetition(idConcorso)
                .then(() => {
                    this.$emit('is-components-ready', this.componentName, true)
                    this.$router.push({ name: routesEnums.COMPETITION_DETAIL_WRAPPER })
                })
                .catch(() => {
                    this.$emit('is-components-ready', this.componentName, true)
                    $('#modalGenericErrorAddCompetition').modal('show')
                    $('#message-body-generic-add-error').html(this.$t('home.modale.errore.concorsiUtente'))
                })
        },
        addCompetition (id_concorso, type) {
            if (id_concorso !== undefined) {
                this.$emit('is-components-ready', this.componentName, false)
                this.getCompetition({
                    idConcorso: id_concorso,
                    opts: null
                })
                    .then(() => {
                        this.getUtenteConcorsi(id_concorso)
                    })
                    .catch(() => {
                        this.$emit('is-components-ready', this.componentName, true)
                        $('#modalGenericErrorAddCompetition').modal('show')
                        $('#message-body-generic-add-error').html(this.$t('home.modale.errore.aggiungiConcorso'))
                    })
            }
        },
        getUtenteConcorsi (id_concorso) {
            this.isRequestsReady('getUserCompetitions', false)
            this.$emit('is-components-ready', this.componentName, false)
            this.getUserCompetitions()
                .then(() => {
                    if (id_concorso) {
                        this.goToCompetition(id_concorso)
                    } else {
                        this.isRequestsReady('getUserCompetitions', true)
                    }
                })
                .catch(() => {
                    this.$emit('is-components-ready', this.componentName, true)
                    $('#button-retry-add').removeAttr('disabled')
                    $('#modalCompetitionsRequestsError').data('bs.modal', null)
                    $('#modalCompetitionsRequestsError').modal({backdrop: 'static', keyboard: false})
                    $('#message-body-competitions-requests-error').html(this.$t('home.modale.errore.concorsiUtente'))
                })
        },
        //#endregion
        onAdvancedClick () {
            $('#modalAvanzata').modal('show');
        },
        /**
         * @deprecated
         */
        isRequestsReady (label, state) {
            // TODO: remove
        }
    }
}
</script>

<style scoped lang="scss">
html.theme-light .badge {
    padding-top: 0.4em;
}
</style>
